import React from 'react';
import { Translate } from 'react-localize-redux';
import { ReactComponent as Towing } from '../../img/undraw/towing.svg';

const Errors = (props) => (
	<div className="min-h-screen w-full flex flex-col font-primary">
		<div className="flex-1 flex flex-col justify-center content-center items-center mb-8">
			<div className="max-w-sm rounded overflow-hidden shadow-none">
				{/*<img src={Towing} className="w-full" alt="Error" />*/}
				<Towing fill="red" className="mb-4 h-40 max-w-full" alt="Error" />
			</div>
			<div className="flex flex-row justify-end content-center items-center mt-4">
				<Translate id="errors.technicalProblem" />
			</div>
		</div>
	</div>
);

export default Errors;
