import React from 'react';

export const UserContext = React.createContext({
  authenticated: false,
  id: '',
  email: '',
  name: '',
  locale: '',
  changeName: (n) => {},
  changeLanguage: (l) => {},
});

export const CustomerContext = React.createContext({
  isThor: true,
  id: '',
  name: '',
  font: '',
  colors: {
    primary: 'black',
    secondary: 'white',
  },
  ids: [],
});
