import MarkerCarOnline from './img/markers/car-online.png';
import MarkerBicycleOnline from './img/markers/bicycle-online.png';
import MarkerCarOffline from './img/markers/car-offline.png';
import MarkerBicycleOffline from './img/markers/bicycle-offline.png';
import MarkerCarNotConnected from './img/markers/car-notconnected.png';
import MarkerBicycleNotConnected from './img/markers/bicycle-notconnected.png';

export const MapStyle = [
	{
		featureType: 'administrative',
		elementType: 'labels.text.fill',
		stylers: [{ color: '#6195a0' }],
	},
	{
		featureType: 'administrative.province',
		elementType: 'geometry.stroke',
		stylers: [{ visibility: 'off' }],
	},
	{
		featureType: 'landscape',
		elementType: 'geometry',
		stylers: [{ lightness: '0' }, { saturation: '0' }, { color: '#f5f5f2' }, { gamma: '1' }],
	},
	{
		featureType: 'landscape.man_made',
		elementType: 'all',
		stylers: [{ lightness: '-3' }, { gamma: '1.00' }],
	},
	{
		featureType: 'landscape.natural.terrain',
		elementType: 'all',
		stylers: [{ visibility: 'off' }],
	},
	{
		featureType: 'poi',
		elementType: 'all',
		stylers: [{ visibility: 'off' }],
	},
	{
		featureType: 'poi.park',
		elementType: 'geometry.fill',
		stylers: [{ color: '#bae5ce' }, { visibility: 'on' }],
	},
	{
		featureType: 'poi.attraction',
		elementType: 'all',
		stylers: [{ visibility: 'on' }],
	},
	{
		featureType: 'poi.medical',
		elementType: 'all',
		stylers: [{ visibility: 'on' }],
	},
	{
		featureType: 'poi.school',
		elementType: 'all',
		stylers: [{ visibility: 'on' }],
	},
	{
		featureType: 'poi.government',
		elementType: 'all',
		stylers: [{ visibility: 'on' }],
	},
	{
		featureType: 'road',
		elementType: 'all',
		stylers: [{ saturation: -100 }, { lightness: 45 }, { visibility: 'simplified' }],
	},
	{
		featureType: 'road.highway',
		elementType: 'all',
		stylers: [{ visibility: 'simplified' }],
	},
	{
		featureType: 'road.highway',
		elementType: 'geometry.fill',
		stylers: [{ color: '#fac9a9' }, { visibility: 'simplified' }],
	},
	{
		featureType: 'road.highway',
		elementType: 'labels.text',
		stylers: [{ color: '#4e4e4e' }],
	},
	{
		featureType: 'road.arterial',
		elementType: 'labels.text.fill',
		stylers: [{ color: '#787878' }],
	},
	{
		featureType: 'road.arterial',
		elementType: 'labels.icon',
		stylers: [{ visibility: 'simplified' }],
	},
	{
		featureType: 'transit',
		elementType: 'all',
		stylers: [{ visibility: 'simplified' }],
	},
	{
		featureType: 'transit.station.airport',
		elementType: 'labels.icon',
		stylers: [{ hue: '#0a00ff' }, { saturation: '-77' }, { gamma: '0.57' }, { lightness: '0' }],
	},
	{
		featureType: 'transit.station.rail',
		elementType: 'labels.text.fill',
		stylers: [{ color: '#43321e' }],
	},
	{
		featureType: 'transit.station.rail',
		elementType: 'labels.icon',
		stylers: [{ hue: '#ff6c00' }, { lightness: '4' }, { gamma: '0.75' }, { saturation: '-68' }],
	},
	{
		featureType: 'water',
		elementType: 'all',
		stylers: [{ color: '#eaf6f8' }, { visibility: 'on' }],
	},
	{
		featureType: 'water',
		elementType: 'geometry.fill',
		stylers: [{ color: '#c7eced' }],
	},
	{
		featureType: 'water',
		elementType: 'labels.text.fill',
		stylers: [{ lightness: '-49' }, { saturation: '-53' }, { gamma: '0.79' }],
	},
];

export const AsyncSleep = (ms) => new Promise((resolve) => setTimeout(() => resolve(), ms));

export const GetCurrentPosition = () =>
	new Promise((resolve) => {
		navigator.geolocation.getCurrentPosition(
			(pos) => {
				resolve({
					lat: pos.coords.latitude,
					lng: pos.coords.longitude,
				});
			},
			(err) => {
				console.log(err);
				resolve(null);
			},
			{ enableHighAccuracy: false, timeout: 3000, maximumAge: 600000 }
		);
	});

export const GetMarker = (type, online) => {
	switch (type.vehicle) {
		case 'car':
			if (type.msg === 'nc') {
				return {
					url: MarkerCarNotConnected,
					scaledSize: new window.google.maps.Size(36, 45),
				};
			}
			return {
				url: online ? MarkerCarOnline : MarkerCarOffline,
				scaledSize: new window.google.maps.Size(36, 45),
			};
		case 'bicycle':
			if (type.msg === 'nc') {
				return {
					url: MarkerBicycleNotConnected,
					scaledSize: new window.google.maps.Size(36, 45),
				};
			}
			return {
				url: online ? MarkerBicycleOnline : MarkerBicycleOffline,
				scaledSize: new window.google.maps.Size(36, 45),
			};

		default:
			return null;
	}
};

export const CheckForIndexHTMLChange = async (indexHTMLURL) => {
	try {
		let resp = await fetch(indexHTMLURL, { method: 'get', mode: 'cors' });
		let text = await resp.text();
		let r = /^.*<script.*\/(main.*\.js).*$/gim.exec(text);
		if (!r || r.length < 2) {
			return;
		}
		let remoteMainScript = r.length > 1 ? r[1] : null;
		if (remoteMainScript === null) {
			return;
		}
		let localMainScript = null;
		let scripts = document.body.getElementsByTagName('script');
		for (let script of scripts) {
			let rl = /^.*\/(main.*\.js).*$/gim.exec(script.src);
			if (!rl || rl.length < 2) {
				continue;
			}
			localMainScript = rl[1];
			break;
		}
		if (localMainScript === null) {
			return;
		}
		return remoteMainScript !== localMainScript;
	} catch (err) {
		console.log(err);
		return false;
	}
};

export const LightenDarkenColor = (color, percent) => {
	let usePound = false;

	if (color[0] === '#') {
		color = color.slice(1);
		usePound = true;
	}
	let num = parseInt(color, 16);
	let amt = Math.round(2.55 * percent);
	let R = (num >> 16) + amt;
	let B = ((num >> 8) & 0x00ff) + amt;
	let G = (num & 0x0000ff) + amt;

	return (
		(usePound ? '#' : '') +
		(0x1000000 + (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 + (G < 255 ? (G < 1 ? 0 : G) : 255))
			.toString(16)
			.slice(1)
	);
};
