import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { LocalizeProvider } from 'react-localize-redux';
import { LoadScript } from '@react-google-maps/api';
import 'cookieconsent';
import App from './presentation';

const AppContainer = () => (
	<LocalizeProvider>
		<BrowserRouter basename="/">
			<LoadScript id="gmap-script-loader" googleMapsApiKey="AIzaSyAbHSxXy67QyEKEdJLXpWDn85yHSNsMuII" loadingElement={<></>}>
				<App />
			</LoadScript>
		</BrowserRouter>
	</LocalizeProvider>
);

ReactDOM.render(<AppContainer />, document.getElementById('root'));
