import env from './env';
const Region = 'eu-west-1';
let amplify = {};

let customerID = 'thor';
switch (env) {
  case 'dev':
    amplify = {
      Auth: {
        identityPoolId: 'eu-west-1:72c4787c-3f56-4e37-8a81-41cb17276f7c',
        region: Region,
        userPoolId: 'eu-west-1_1O5PfjLg3',
        userPoolWebClientId: '16ggi9aca50j0e8irnroaassfi',
      },
      Storage: {
        AWSS3: {
          bucket: 'dev.documents.thor.tools',
          region: Region,
        },
      },
      API: {
        endpoints: [
          {
            name: 'EndUserSignedUp',
            endpoint: `https://lambda.${Region}.amazonaws.com/2015-03-31/functions/DevThorEndUserSignedUp/invocations`,
            service: 'lambda',
            region: Region,
          },
          {
            name: 'GetChargeboxesInMapBound',
            endpoint: `https://lambda.${Region}.amazonaws.com/2015-03-31/functions/DevThorGetChargeboxesInMapBound/invocations`,
            service: 'lambda',
            region: Region,
          },
          {
            name: 'GetClientCustomizations',
            endpoint: `https://lambda.${Region}.amazonaws.com/2015-03-31/functions/DevThorGetClientCustomizations/invocations`,
            service: 'lambda',
            region: Region,
          },
          {
            name: 'GetClientByCDN',
            endpoint: `https://lambda.${Region}.amazonaws.com/2015-03-31/functions/DevThorGetClientByCDN/invocations`,
            service: 'lambda',
            region: Region,
          },
        ],
        graphql_headers: async () => {
          try {
            let { default: Auth } = await import('@aws-amplify/auth');
            let user = await Auth.currentAuthenticatedUser();
            return { cognitoUser: user.username, customClient: customerID };
          } catch (err) {
            console.log(err);
            return { cognitoUser: '', customClient: customerID };
          }
        },
        aws_appsync_graphqlEndpoint: 'https://qt6uihb54jgqdbfhbgkv3yyb4u.appsync-api.eu-west-1.amazonaws.com/graphql',
        aws_appsync_region: Region,
        aws_appsync_authenticationType: 'AWS_IAM',
        aws_appsync_apiKey: 'null',
      },
    };
    break;
  case 'prd':
    amplify = {
      Auth: {
        identityPoolId: 'eu-west-1:8da0a5a1-085b-4166-8051-b10d1bd1ae15',
        region: Region,
        userPoolId: 'eu-west-1_9YRFp5MnE',
        userPoolWebClientId: '1cpvik76sj9tbuuebgq9i0nj23',
      },
      Storage: {
        AWSS3: {
          bucket: 'documents.thor.tools',
          region: Region,
        },
      },
      API: {
        endpoints: [
          {
            name: 'EndUserSignedUp',
            endpoint: `https://lambda.${Region}.amazonaws.com/2015-03-31/functions/PrdThorEndUserSignedUp/invocations`,
            service: 'lambda',
            region: Region,
          },
          {
            name: 'GetChargeboxesInMapBound',
            endpoint: `https://lambda.${Region}.amazonaws.com/2015-03-31/functions/PrdThorGetChargeboxesInMapBound/invocations`,
            service: 'lambda',
            region: Region,
          },
          {
            name: 'GetClientCustomizations',
            endpoint: `https://lambda.${Region}.amazonaws.com/2015-03-31/functions/PrdThorGetClientCustomizations/invocations`,
            service: 'lambda',
            region: Region,
          },
          {
            name: 'GetClientByCDN',
            endpoint: `https://lambda.${Region}.amazonaws.com/2015-03-31/functions/PrdThorGetClientByCDN/invocations`,
            service: 'lambda',
            region: Region,
          },
        ],
        graphql_headers: async () => {
          try {
            let { default: Auth } = await import('@aws-amplify/auth');
            let user = await Auth.currentAuthenticatedUser();
            return { cognitoUser: user.username, customClient: customerID };
          } catch (err) {
            console.log(err);
            return { cognitoUser: '', customClient: customerID };
          }
        },
        aws_appsync_graphqlEndpoint: 'https://rcbqdble4nekte4kluoyjj2xru.appsync-api.eu-west-1.amazonaws.com/graphql',
        aws_appsync_region: Region,
        aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
        aws_appsync_apiKey: 'null',
      },
    };
    break;

  default:
    break;
}

const SetCustomerID = (c) => (customerID = c);
export { amplify as default, Region, SetCustomerID };
