import React from 'react';
//import ThorLogo from '../../img/thor/thor-vertical.png';

const Loading = () => (
	<div className="absolute top-0 z-50 h-screen w-full flex flex-col justify-center items-center content-center bg-white font-primary">
		{/*
		<img src={ThorLogo} alt="Logo" className="max-w-xs sm:max-w-sm w-48 mb-8" />
		<div className="w-16 h-16 border-t-2 border-r-2 border-teal-600 rounded-full spin" />
		*/}
		<section className="spin-container">
			<div>
				<div>
					<span className="spin-one spin-h6"></span>
					<span className="spin-two spin-h3"></span>
				</div>
			</div>

			<div>
				<div>
					<span className="spin-one spin-h1"></span>
					<span className="spin-two spin-h4"></span>
				</div>
			</div>

			<div>
				<div>
					<span className="spin-one spin-h5"></span>
					<span className="spin-two spin-h2"></span>
				</div>
			</div>
		</section>
	</div>
);

export default Loading;
